<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Data Pegawai
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group label="NIP" label-for="nip">
                <validation-provider #default="{ errors }" name="nip" rules="required">
                  <b-form-input id="nip" v-model="dataParent.nip" type="number" :state="errors.length > 0 ? false : null"
                    placeholder="NIP" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nama" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input id="name" v-model="dataParent.name" type="text" :state="errors.length > 0 ? false : null"
                    placeholder="Nama" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nama Panggilan" label-for="nickname">
                <validation-provider #default="{ errors }" name="nickname" rules="required">
                  <b-form-input id="nickname" v-model="dataParent.nickname" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Nama Penggilam" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Gelar Awal" label-for="first_title">
                <validation-provider #default="{ errors }" name="first_title" rules="required">
                  <b-form-input id="first_title" v-model="dataParent.first_title" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Gelar Awal" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Gelar Akhir" label-for="last_title">
                <validation-provider #default="{ errors }" name="last_title" rules="required">
                  <b-form-input id="last_title" v-model="dataParent.last_title" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Gelar Akhir" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider #default="{ errors }" name="Jenis Kelamin" rules="required">
                <b-form-group label="Jenis Kelamin" label-for="gender" :state="errors.length > 0 ? false : null">
                  <v-select id="gender" v-model="dataParent.gender" :reduce="(status) => status.value"
                    placeholder="Pilih Jenis Kelamin" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataGender" label="text" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Tempat Lahir" label-for="birth_place">
                <validation-provider #default="{ errors }" name="birth_place" rules="required">
                  <b-form-input id="birth_place" v-model="dataParent.birth_place" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Tempat Lahir" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Tanggal Lahir" label-for="birth_date">
                <validation-provider #default="{ errors }" name="Tanggal Lahir" rules="required">
                  <b-form-input id="birth_date" v-model="dataParent.birth_date" type="date"
                    :state="errors.length > 0 ? false : null" placeholder="21-05-1992" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider #default="{ errors }" name="Pendidikan Terakhir" rules="required">
                <b-form-group label="Pendidikan Orang Tua" label-for="education_level"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="education_level" v-model="dataParent.education_level" :reduce="(status) => status.value"
                    placeholder="Pilih Pendidikan Terakhir" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataParentEducation" label="text" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Institusi Pendidikan" label-for="educational_institution">
                <validation-provider #default="{ errors }" name="educational_institution" rules="required">
                  <b-form-input id="educational_institution" v-model="dataParent.educational_institution" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Institusi Pendidikan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="KTP" label-for="ktp_number">
                <validation-provider #default="{ errors }" name="ktp_number" rules="required">
                  <b-form-input id="ktp_number" v-model="dataParent.ktp_number" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="KTP" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider #default="{ errors }" name="Agama" rules="required">
                <b-form-group label="Agama" label-for="religion" :state="errors.length > 0 ? false : null">
                  <v-select id="religion" v-model="dataParent.religion" :reduce="(religion) => religion.value"
                    placeholder="Pilih Agama" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataReligion"
                    label="text" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="ethnic" rules="required">
                <b-form-group label="Suku" label-for="ethnic" :state="errors.length > 0 ? false : null">
                  <v-select id="ethnic" v-model="dataParent.ethnic" :reduce="(ethnic) => ethnic.id" placeholder="Suku"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listEthnic" label="ethnic_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Kebangsaan" label-for="nationality">
                <validation-provider #default="{ errors }" name="nationality" rules="required">
                  <b-form-input id="nationality" v-model="dataParent.nationality" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Kebangsaan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Status Menikah" rules="required">
                <b-form-group label="Status Menikah" label-for="marital_status" :state="errors.length > 0 ? false : null">
                  <v-select id="marital_status" v-model="dataParent.marital_status"
                    :reduce="(marital_status) => marital_status.value" placeholder="Pilih Status Menikah"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataMaritalStatus" label="text" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Aktif" rules="required">
                <b-form-group label="Aktif" label-for="is_active" :state="errors.length > 0 ? false : null">
                  <v-select id="is_active" v-model="dataParent.is_active" :reduce="(is_active) => is_active.value"
                    placeholder="Pilih Aktif / Tidak Aktif" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataIsActive" label="text" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="type" rules="required">
                <b-form-group label="Tipe Pegawai" label-for="type" :state="errors.length > 0 ? false : null">
                  <v-select id="type" v-model="dataParent.type" :reduce="(type) => type.real_id"
                    placeholder="Tipe Pegawai" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listEmployeeType" label="employee_type_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Provinsi" rules="required">
                <b-form-group label="Provinsi" label-for="province" :state="errors.length > 0 ? false : null">
                  <v-select id="province" v-model="locations.province" placeholder="Pilih Provinsi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataProvinces" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Kabupaten/Kota" rules="required">
                <b-form-group label="Kabupaten/Kota" label-for="city" :state="errors.length > 0 ? false : null">
                  <v-select id="city" v-model="locations.city" placeholder="Pilih Kabupaten/Kota"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterCities" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Kecamatan" rules="required">
                <b-form-group label="Kecamatan" label-for="distric" :state="errors.length > 0 ? false : null">
                  <v-select id="distric" v-model="locations.distric" placeholder="Pilih Kecamatan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterDistrics" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Desa" rules="required">
                <b-form-group label="Desa" label-for="subdistrict" :state="errors.length > 0 ? false : null">
                  <v-select id="subdistrict" v-model="locations.subdistrict" placeholder="Pilih Desa"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterSubDistrics" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="RW" label-for="rw">
                <validation-provider #default="{ errors }" name="rw" rules="required">
                  <b-form-input id="rw" v-model="dataParent.rw" :state="errors.length > 0 ? false : null"
                    placeholder="11" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="RT" label-for="rt">
                <validation-provider #default="{ errors }" name="rt" rules="required">
                  <b-form-input id="rt" v-model="dataParent.rt" :state="errors.length > 0 ? false : null"
                    placeholder="21" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Alamat" label-for="address">
                <validation-provider #default="{ errors }" name="address" rules="required">
                  <b-form-input id="address" v-model="dataParent.address" :state="errors.length > 0 ? false : null"
                    placeholder="Jl. Kaliurang Km. 7 No.B5" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="No. Handphone 1" label-for="phone_1">
                <validation-provider #default="{ errors }" name="No. Handphone 1" rules="required">
                  <b-form-input id="phone_1" v-model="dataParent.phone_1" :state="errors.length > 0 ? false : null"
                    placeholder="08xxxxxxxxx" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="No. Handphone 2" label-for="phone_2">
                <validation-provider #default="{ errors }" name="No. Handphone 2" rules="required">
                  <b-form-input id="phone_2" v-model="dataParent.phone_2" :state="errors.length > 0 ? false : null"
                    placeholder="08xxxxxxxxx" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="No. Whatsapp" label-for="whatsapp">
                <validation-provider #default="{ errors }" name="No. Whatsapp" rules="required">
                  <b-form-input id="whatsapp" v-model="dataParent.whatsapp" :state="errors.length > 0 ? false : null"
                    placeholder="08xxxxxxxxx" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataParent: {
        nip: '',
        name: '',
        nickname: '',
        first_title: '',
        last_title: '',
        gender: '',
        birth_place: '',
        birth_date: '',
        education_level: '',
        educational_institution: '',
        ktp_number: '',
        religion: '',
        ethnic: '',
        nationality: '',
        marital_status: '',
        is_active: '',
        type: '',
        province: '',
        city: '',
        district: '',
        subdistrict: '',
        rw: '',
        rt: '',
        address: '',
        phone_1: '',
        phone_2: '',
        whatsapp: '',
      },
      locations: {
        province: '',
        city: '',
        distric: '',
        subdistric: '',
      },
      dataGender: [
        { value: 'M', text: 'Laki-laki' },
        { value: 'F', text: 'Perempuan' },
      ],
      dataReligion: [
        { value: 1, text: 'Islam' },
        { value: 2, text: 'Protestan' },
        { value: 3, text: 'Katolik' },
        { value: 4, text: 'Hindu' },
        { value: 5, text: 'Buddha' },
        { value: 6, text: 'Khonghucu' },
      ],
      dataReadingQuran: [
        { value: 'lancar', text: 'Lancar' },
        { value: 'belum_lancar', text: 'Belum Lancar' },
        { value: 'belum_bisa', text: 'Belum Bisa' },
      ],
      dataEducation: [{ value: 1, text: 'SD/MI' }],
      dataParentStatus: [
        { value: '1', text: 'Ayah' },
        { value: '2', text: 'Ibu' },
        { value: '3', text: 'Wali' },
      ],
      dataParentEducation: [
        { value: '1', text: 'SD' },
        { value: '2', text: 'SMP' },
        { value: '3', text: 'SMA' },
        { value: '4', text: 'S1' },
        { value: '5', text: 'S2' },
        { value: '6', text: 'S3' },
      ],
      dataMutations: [
        { value: 'Y', text: 'Ya' },
        { value: 'N', text: 'Tidak' },
      ],
      dataMaritalStatus: [
        { value: 1, text: 'Menikah' },
        { value: 2, text: 'Belum Menikah' },
      ],
      dataIsActive: [
        { value: 1, text: 'Aktif' },
        { value: 0, text: 'Tidak Aktif' },
      ],
      dataClasss: [{ value: '4nvoeygeoxw3zpra', text: 'VII' }],
      listEthnic: [],
      listEmployeeType: [],
    }
  },
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
    filterCitiesFather() {
      return this.dataCities.filter(
        item => item.parent === this.locations.father_province.wilayah_id,
      )
    },
    filterCitiesMother() {
      return this.dataCities.filter(
        item => item.parent === this.locations.mother_province.wilayah_id,
      )
    },
    filterDistricsFather() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.father_city.wilayah_id,
      )
    },
    filterDistricsMother() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.mother_city.wilayah_id,
      )
    },
    filterSubDistricsFather() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.father_distric.wilayah_id,
      )
    },
    filterSubDistricsMother() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.mother_distric.wilayah_id,
      )
    },
  },
  created() {
    this.getethnic(),
      this.getemployeetype()
  },
  methods: {
    async getethnic() {
      try {
        const response = await this.$http.get('/ethnics')
        this.listEthnic = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getemployeetype() {
      try {
        const response = await this.$http.get('/employeetypes')
        this.listEmployeeType = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },

    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        dataFormParent.append('province', this.locations.province.name)
        dataFormParent.append('city', this.locations.city.name)
        dataFormParent.append('district', this.locations.distric.name)
        dataFormParent.append('subdistrict', this.locations.subdistric.name)
        await this.$http.post('/employees', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Pegawai Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-pegawai' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Pegawai Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
